import '@/assets/css/element-variables.scss'
import '@/assets/css/style.scss'
import '@/assets/css/uptodate.scss'
import '@/utils/mixinCheckPermission.js'
import '@/utils/mixinSendAmplitude.js'
import 'sweetalert2/dist/sweetalert2.min.css'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'
import 'vue-select/dist/vue-select.css'
import 'vue-toast-notification/dist/theme-sugar.css'
import './plugins'

import AdminDashboard from './components/AdminDashboard.vue'
import AdminLayout from './layouts/Admin/AdminLayout.vue'
import App from './App.vue'
import AppDashboard from './components/AppDashboard.vue'
import BootstrapVue from 'bootstrap-vue'
import Bus from './utils/EventBus.js'
import ClinicDashboard from './components/ClinicDashboard.vue'
import ClinicLayout from './layouts/ClinicLayout.vue'
import CoolLightBox from 'vue-cool-lightbox'
import DefaultAdminTemplate from './templates/default/DefaultAdminTemplate.vue'
import DefaultPreviewTemplate from './templates/default/DefaultPreviewTemplate.vue'
import DefaultTemplate from './templates/default/DefaultTemplate.vue'
import DoctorAppLayout from './layouts/DoctorAppLayout.vue'
import DoctorDashboard from './components/DoctorDashboard.vue'
import DoctorLayout from './layouts/DoctorLayout.vue'
import DoctorMdtLayout from './layouts/DoctorMdtLayout.vue'
import EmptyLayout from './layouts/EmptyLayout.vue'
import LazyLoadDirective from '@/directives/LazyLoadDirective'
import LoginLayout from './layouts/LoginLayout.vue'
import OrganizationLayout from './layouts/OrganizationLayout.vue'
import RequestFactory from './api/RequestFactory'
import UserLayout from './layouts/UserLayout.vue'
import VCalendar from 'v-calendar'
import VoerroTagsInput from '@voerro/vue-tagsinput'
import Vue from 'vue'
import VueProgressBar from 'vue-progressbar'
import VueQrcode from '@chenfengyuan/vue-qrcode'
import VueShortkey from 'vue-shortkey'
import VueSweetalert2 from 'vue-sweetalert2'
import VueToast from 'vue-toast-notification'
import amplitude from 'amplitude-js'
import i18n from './lang'
import moment from 'moment/min/moment-with-locales'
import router from './router'
import { store } from './store'

Vue.directive('lazyload', LazyLoadDirective)

Vue.use(VCalendar, {
  // componentPrefix: 'vc',
})

// import Calendar from 'v-calendar/lib/components/calendar.umd'
// import DatePicker from 'v-calendar/lib/components/date-picker.umd'

// Vue.component('calendar', Calendar)
// Vue.component('date-picker', DatePicker)

require('@voerro/vue-tagsinput/dist/style.css')

Vue.use(BootstrapVue)

Vue.use(VueShortkey)

amplitude.getInstance().init(process.env.VUE_APP_AMPLITUDE_API_KEY)
window.amplitude = amplitude.getInstance()
Vue.component('empty-layout', EmptyLayout)
Vue.component('clinic-layout', ClinicLayout)
Vue.component('admin-layout', AdminLayout)
Vue.component('doctor-layout', DoctorLayout)
Vue.component('login-layout', LoginLayout)
Vue.component('app-dashboard', AppDashboard)
Vue.component('admin-dashboard', AdminDashboard)
Vue.component('user-layout', UserLayout)
Vue.component('clinic-dashboard', ClinicDashboard)
Vue.component('doctor-dashboard', DoctorDashboard)

Vue.component('default-admin-template', DefaultAdminTemplate)
Vue.component('default-preview-template', DefaultPreviewTemplate)
Vue.component('default-template', DefaultTemplate)

Vue.component('doctor-app-layout', DoctorAppLayout)
Vue.component('doctor-mdt-layout', DoctorMdtLayout)
Vue.component('organization-layout', OrganizationLayout)

Vue.component('tags-input', VoerroTagsInput)

Vue.config.productionTip = false
if (process.env.NODE_ENV !== 'development') {
  Vue.config.devtools = false
} else {
  Vue.config.devtools = true
}

window.axios = require('axios')
Vue.prototype.$rf = new RequestFactory()

const EventBus = new Vue()
Vue.prototype.$eventBus = EventBus

Vue.component(VueQrcode.name, VueQrcode)
Vue.use(CoolLightBox)
Vue.use(Bus)

Vue.use(VueProgressBar, {
  color: '#20419B',
  failedColor: 'red',
  height: '2px'
})

Vue.use(VueToast, {
  position: 'top'
})
Vue.use(VueSweetalert2)
window.$toast = {}
window.$toast.show = (message) => {
  window.$('#toast__message').html(message)
  window.$('#toast__box').modal('show')
}
window.$toast.noti = (status, message) => {
  if (status) {
    Vue.$toast.open({
      message: message,
      type: 'success'
      // all other options
    })
  } else {
    Vue.swal.fire({
      html: message,
      icon: 'error'
    })
  }
}
// prototype
const echo = Vue.observable({ echo: null })

Object.defineProperty(Vue.prototype, '$echo', {
  get () {
    return echo.echo
  },

  set (value) {
    echo.echo = value
  }
})
const conectedEcho = Vue.observable({ conectedEcho: null })

Object.defineProperty(Vue.prototype, '$conectedEcho', {
  get () {
    return conectedEcho.conectedEcho
  },

  set (value) {
    conectedEcho.conectedEcho = value
  }
})

const uploading = Vue.observable({ uploading: false })

Object.defineProperty(Vue.prototype, '$uploading', {
  get () {
    return uploading.uploading
  },

  set (value) {
    uploading.uploading = value
  }
})

const lang = Vue.observable({ lang: false })

Object.defineProperty(Vue.prototype, '$lang', {
  get () {
    return lang.lang
  },

  set (value) {
    lang.lang = value
  }
})

const conversationsClient = Vue.observable({ conversationsClient: null })

Object.defineProperty(Vue.prototype, '$conversationsClient', {
  get () {
    return conversationsClient.conversationsClient
  },

  set (value) {
    conversationsClient.conversationsClient = value
  }
})

const conversationsList = Vue.observable({ conversationsList: [] })

Object.defineProperty(Vue.prototype, '$conversationsList', {
  get () {
    return conversationsList.conversationsList
  },

  set (value) {
    conversationsList.conversationsList = value
  }
})

const fcmToken = Vue.observable({ fcmToken: null })

Object.defineProperty(Vue.prototype, '$fcmToken', {
  get () {
    return fcmToken.fcmToken
  },

  set (value) {
    fcmToken.fcmToken = value
  }
})
const chattingCvstId = Vue.observable({ chattingCvstId: '' })

Object.defineProperty(Vue.prototype, '$chattingCvstId', {
  get () {
    return chattingCvstId.chattingCvstId
  },

  set (value) {
    chattingCvstId.chattingCvstId = value
  }
})
const clinicId = Vue.observable({ clinicId: null })

Object.defineProperty(Vue.prototype, '$clinicId', {
  get () {
    return clinicId.clinicId
  },

  set (value) {
    clinicId.clinicId = value
  }
})

const user = Vue.observable({ user: null })

Object.defineProperty(Vue.prototype, '$user', {
  get () {
    return user.user
  },

  set (value) {
    user.user = value
  }
})

const jwtToken = Vue.observable({ jwtToken: null })

Object.defineProperty(Vue.prototype, '$jwtToken', {
  get () {
    return jwtToken.jwtToken
  },

  set (value) {
    jwtToken.jwtToken = value
  }
})

const globalClinic = Vue.observable({ globalClinic: null })
Object.defineProperty(Vue.prototype, '$globalClinic', {
  get () {
    return globalClinic.globalClinic
  },

  set (value) {
    globalClinic.globalClinic = value
  }
})

const globalOrg = Vue.observable({ globalOrg: null })
Object.defineProperty(Vue.prototype, '$globalOrg', {
  get () {
    return globalOrg.globalOrg
  },

  set (value) {
    globalOrg.globalOrg = value
  }
})

const orgModel = Vue.observable({ orgModel: null })
Object.defineProperty(Vue.prototype, '$orgModel', {
  get () {
    return orgModel.orgModel || 'hospital'
  },

  set (value) {
    orgModel.orgModel = value
  }
})

// const messageUnreadCount = Vue.observable({ messageUnreadCount: null })
// Object.defineProperty(Vue.prototype, '$messageUnreadCount', {
//   get () {
//     return messageUnreadCount.messageUnreadCount
//   },

//   set (value) {
//     messageUnreadCount.messageUnreadCount = value
//   }
// })

const reloadChat = Vue.observable({ reloadChat: 0 })
Object.defineProperty(Vue.prototype, '$reloadChat', {
  get () {
    return reloadChat.reloadChat
  },

  set (value) {
    reloadChat.reloadChat = value
  }
})

const multiUploading = Vue.observable({ multiUploading: [] })
Object.defineProperty(Vue.prototype, '$multiUploading', {
  get () {
    return multiUploading.multiUploading
  },

  set (value) {
    multiUploading.multiUploading = value
  }
})

const refresh = Vue.observable({ refresh: 0 })

Object.defineProperty(Vue.prototype, '$refresh', {
  get () {
    return refresh.refresh
  },

  set (value) {
    refresh.refresh = value
  }
})

const featureList = Vue.observable({ featureList: [] })

Object.defineProperty(Vue.prototype, '$featureList', {
  get () {
    return featureList.featureList
  },

  set (value) {
    featureList.featureList = value
  }
})

const featureWsList = Vue.observable({ featureWsList: [] })

Object.defineProperty(Vue.prototype, '$featureWsList', {
  get () {
    return featureWsList.featureWsList
  },

  set (value) {
    featureWsList.featureWsList = value
  }
})

const featureOrgList = Vue.observable({ featureOrgList: [] })

Object.defineProperty(Vue.prototype, '$featureOrgList', {
  get () {
    return featureOrgList.featureOrgList
  },

  set (value) {
    featureOrgList.featureOrgList = value
  }
})

const preRoutes = Vue.observable({ preRoutes: [] })

Object.defineProperty(Vue.prototype, '$preRoutes', {
  get () {
    return preRoutes.preRoutes
  },

  set (value) {
    preRoutes.preRoutes = value
  }
})

const appMode = Vue.observable({ appMode: null })
Object.defineProperty(Vue.prototype, '$appMode', {
  get () {
    return appMode.appMode
  },

  set (value) {
    appMode.appMode = value
  }
})

const chatClient = Vue.observable({ chatClient: null })
Object.defineProperty(Vue.prototype, '$chatClient', {
  get () {
    return chatClient.chatClient
  },

  set (value) {
    chatClient.chatClient = value
  }
})

const chatClientCount = Vue.observable({ chatClientCount: null })
Object.defineProperty(Vue.prototype, '$chatClientCount', {
  get () {
    return chatClientCount.chatClientCount
  },

  set (value) {
    chatClientCount.chatClientCount = value
  }
})

const e3kit = Vue.observable({ e3kit: null })
Object.defineProperty(Vue.prototype, '$e3kit', {
  get () {
    return e3kit.e3kit
  },

  set (value) {
    e3kit.e3kit = value
  }
})
window.$e3kit = e3kit.e3kit

Vue.prototype.moment = moment
window.moment = moment

Vue.directive('click-outside', {
  bind: function (el, binding, vnode) {
    el.event = function (event) {
      if (!(el === event.target || el.contains(event.target))) {
        vnode.context[binding.expression](event)
      }
    }
    document.body.addEventListener('click', el.event)
  },
  unbind: function (el) {
    document.body.removeEventListener('click', el.event)
  }
})

new Vue({
  router,
  i18n,
  store,
  render: (h) => h(App)
}).$mount('#app')
